






















import { Component, Vue } from 'vue-property-decorator';
import FooterComponent from '../components/main/footer.component.vue';
import ResetPasswordForm from '../components/reset-password/resetPasswordForm.vue';

@Component({
  components: {
    ResetPasswordForm,
    FooterComponent,
  },
})
export default class ResetPassword extends Vue {
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!vm.$store.state.auth.resetPassword) {
        vm.$router.push('/');
      }
    });
  }
}
