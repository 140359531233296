



































































































import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';
import Toaster from '../../mixins/toaster.mixin';
import Language from '../../mixins/language.mixin';
import { Lang } from '../../types/general.type';
import HomeProvider from '../../providers/home.provider';

@Component
export default class ResetPasswordForm extends mixins(Toaster, Language) {
  currentPassword = '';

  newPassword = '';

  confirmPassword = '';

  resErr = null;

  valid = false;

  hideCurrentPassword = true;

  hideNewPassword = true;

  hideConfirmPassword = true;

  passwordRules = [(v: string) => !!v || this.$t('requiredField')];

  newpasswordRules = [
    (v: string) => !!v || this.$t('requiredField'),
    (v: string) => (v && v.length <= 50) || `${this.$t('maxLetters')} 50`,
    (v: string) => (v && this.validatePAss(v)) || this.$t('WEAK_PASSWORD'),
  ];

  confirmPasswordRoles = [
    (v: string) => !!v || this.$t('requiredField'),
    (v: string) => v === this.newPass() || this.$t('NOT_CONFIRMED'),
  ];

  $refs!: {
    form: HTMLFormElement;
  };

  homeProvider = new HomeProvider();

  get resetMessage() {
    return this.$store.state.auth.resetMessage;
  }

  get userName() {
    return this.$store.state.auth.username;
  }

  newPass() {
    return this.newPassword;
  }

  async onConfirmClick() {
    if (!this.$refs.form.validate()) {
      return;
    }
    try {
      const message: Lang = await this.homeProvider.changePassword(
        this.userName,
        this.currentPassword,
        this.newPassword,
      );
      this.successToaster(message);
      this.logout();
    } catch (err) {
      this.resErr = err;
    }
  }

  logout() {
    this.$store.commit('logout');
    const redirect = this.$route.query.redirect ? `${this.$route.query.redirect}` : '/';
    this.$router.push(`/login?redirect=${redirect}`);
  }

  changeLang() {
    this.switchLang();
  }

  validatePAss(v) {
    const regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/;
    return regExp.test(v);
  }
}
